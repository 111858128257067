import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import toast from '@/utils/toast';
import smartDeviceStoreModule from '../smartDeviceStoreModule';

export default function useSmartDeviceList() {
  const STORE_MODULE_NAME = 'smartDevice';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, smartDeviceStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('update', 'smart-device') && !vm.$can('delete', 'smart-device');
  const { t } = useI18nUtils();
  // Table Handlers
  const columns = [
    {
      label: t('Mã'),
      field: 'code',
      width: '80px',
    },
    {
      label: t('Thao tác'),
      field: 'action',
      tdClass: 'text-center',
      sortable: false,
      width: '100px',
      hidden: isHidden,
    },
    {
      label: t('Nhà cung cấp'),
      field: 'providerObject.title',
    },
    {
      label: t('Thông tin thiết bị'),
      field: 'name',
    },

    {
      label: t('Loại'),
      field: 'typeObject.title',
    },
    {
      label: t('Trạng thái liên kết'),
      field: 'isSynced',
    },
    {
      label: t('Trạng thái bật'),
      field: 'isEnable',
    },
    {
      label: t('Công tơ'),
      field: 'meter.name',
    },
    {
      label: t('Tòa nhà'),
      field: 'meter.apartment.name',
    },
    {
      label: t('Căn hộ'),
      field: 'meter.room.name',
    },

  ];
  const rows = ref([]);
  const totalAmount = ref(0);
  // filter
  const type = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   handler
  const isHandlerSidebarActive = ref(false);

  //   smartDevice
  const blankItem = {
    id: 0,
    name: '',
    deviceId: '',
    type: '',
    typeObject: null,
  };
  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const deleteItems = ref([]);
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  //   API Call
  const fetchSmartDevices = () => {
    store
      .dispatch('smartDevice/fetchSmartDevices', serverParams.value)
      .then(response => {
        const { total, items, amount } = response.data;
        totalRecords.value = total;
        rows.value = items;
        totalAmount.value = amount;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteSmartDevices = smartDevices => {
    store
      .dispatch('smartDevice/deleteSmartDevices', {
        ids: smartDevices.map(_obj => _obj.id),
      })
      .then(() => {
        fetchSmartDevices();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const onSubmit = () => {
    store
      .dispatch('smartDevice/createSmartDevice', item.value)
      .then(() => {
        fetchSmartDevices();
        isHandlerSidebarActive.value = false;
      });
  };

  const createSmartDevice = data => {
    store.dispatch('smartDevice/createSmartDevice', data).then(() => {
      fetchSmartDevices();
    });
  };

  const updateSmartDevice = data => {
    store.dispatch('smartDevice/updateSmartDevice', data).then(() => {
      fetchSmartDevices();
    });
  };

  const changeDeviceStatus = dataItem => {
    store.dispatch('smartDevice/changeDeviceStatus', { smartDeviceId: dataItem.id, enable: !dataItem.isEnable }).then(() => {
      fetchSmartDevices();
    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  const onSelectSmartDevice = object => {
    item.value = object;
    isHandlerSidebarActive.value = true;
  };

  const onEditItem = val => {
    item.value = val;
  };

  const onDeleteSmartDevice = val => {
    deleteItems.value = val && val.id > 0 ? [val] : selectedRows.value;
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchSmartDevices();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const setDeleteEnable = () => {
    // Neu co so quy mac dinh thi khong hien thi nut xoa
    if (!selectedRows.value || selectedRows.value.length === 0 || selectedRows.value.filter(_obj => _obj.default).length > 0) {
      return false;
    }

    return true;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(type, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.type = val.value;
    } else {
      delete filter.type;
    }
    updateParams({ filter });
  });

  return {
    isHandlerSidebarActive,
    item,
    deleteItems,
    columns,
    rows,
    totalAmount,
    type,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    changeDeviceStatus,
    onSubmit,
    onSelectSmartDevice,
    resetItem,
    onEditItem,
    fetchSmartDevices,
    deleteSmartDevices,
    createSmartDevice,
    updateSmartDevice,
    setDeleteEnable,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onDeleteSmartDevice,
    t,
  };
}
